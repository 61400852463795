import {gsap} from "gsap";

export function initPage() {
    console.log('INIT PAGE: about');
    initScrollers();
}

function initScrollers() {
    gsap.to('.About', {
        scrollTrigger: {
            trigger: ".About",
            toggleActions: "play none none none",
            //toggleClass: "onScreen",
            markers: false,
            scrub: true,
            start: "top top",
            onUpdate: self => {
                //console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
                translateScroller(self.progress);
            }
        }
    });
}

export function translateScroller(progress) {

    const setScrollOpacity = gsap.quickSetter(".SectionBoxImage__ScrollCta", "opacity");

    let opacity = 0;
    if (progress <= 0.11) {
        // Scroller
        opacity = 1 - 8 * progress;
        if (opacity < 0) {
            opacity = 0;
        }
    }
    setScrollOpacity(opacity);

}
