import {gsap} from "gsap";
import $ from "jquery"

export function initPage() {
    console.log('INIT PAGE: terms os use');
    init();
}

let contentMarkers = [];
let lastContent;

function init() {
    gsap.defaults({overwrite: 'auto'});
    //gsap.set(".TermsOfUse__Menu > *", {xPercent: 30, yPercent: 50});

    gsap.to('.TermsOfUse', {
        scrollTrigger: {
            trigger: ".TermsOfUse__Container",
            start: "top 10%",
            markers: false,
            end: "bottom bottom",
            onUpdate: getCurrentSection,
            pin: ".TermsOfUse__Menu"
        }
    });

    contentMarkers = gsap.utils.toArray(".TermsOfUse__ContentInfo");

    //
    contentMarkers.forEach(marker => {
        let $marker = $(marker);

        let $menu = $('a[href*=\'#' + $marker.attr('id') + '\']');
        marker.enter = function () {
            $menu.addClass('isCurrent');
        }
        marker.leave = function () {
            $menu.removeClass('isCurrent');
        }
    });

}

let newContent;

function getCurrentSection() {

    const currScroll = scrollY;

    contentMarkers.forEach(marker => {
        if (currScroll > marker.offsetTop - innerHeight / 2) {
            newContent = marker;
        }
    });

    if (newContent && (lastContent == null || !newContent.isSameNode(lastContent))) {
        if (lastContent) {
            lastContent.leave();
        }
        newContent.enter();
        lastContent = newContent;
    }
}
