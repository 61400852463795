import $ from "jquery";

export function initPage() {
    console.log('INIT PAGE: Partners Locator');
    init();
}

function init() {
    let timeout = {};
    const input = $(".DealerLocator__SearchInput");
    input.on("input", function () {
        clearTimeout(timeout);
        timeout = setTimeout(searchPartner, 1000);
    });
}

function searchPartner() {

    let searchText = $('.DealerLocator__SearchInput').val();
    console.log('Dealer to be searched: ', searchText);
    $(".DealerLocator__Search").addClass("isSearching");

    $(".DealerLocator__SearchResults").find('.DealerLocator__SearchResult').each(function () {
        let dealer = $(this).data("dealer");
        if (dealer.town.toLowerCase().includes(searchText) || dealer.cap.toLowerCase().includes(searchText)) {
            $(this).show();
        } else {
            $(this).hide();
        }
    });

}

