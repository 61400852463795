import {gsap} from "gsap";
import {breakpoints} from "./breakpoints.js";

export function init() {
    //console.log('INIT: sticky reveal');

    const mm = gsap.matchMedia();
    mm.add({
        isDesktop: `(min-width: ${breakpoints.md}px)`,
        isMobile: `(max-width: ${breakpoints.md - 1}px)`
    }, (context) => {
        _page.init(context);
    });
}

const _page = {
    isDesktop: false,
    isMobile: false,

    initScrollers: function() {
        gsap.to('.SectionPage__StickyContainer', {
            scrollTrigger: {
                normalizeScroll: {allowNestedScroll: true},
                preventDefault: true,
                trigger: ".SectionPage__Sticky",
                toggleActions: "play none none none",
                toggleClass: "onScreen",
                markers: false,
                scrub: true,
                start: "top top",
                end: "bottom top",
                pin: ".SectionPage__Sticky",
                onUpdate: self => {
                    this.translateStickyPageFirstView(self.progress);
                }
            }
        });
    },

    translateStickyPageFirstView: function(progress) {
        // progress start=0 middle=0.5 end = 1
        //console.log("calculated progress:", progress.toFixed(3));
        //console.log(window.innerHeight);

        /*if (progress > 0.50) {
            progress = 0.50;
        }*/

        let hLR = (progress * 38).toFixed(6) + 'vw';
        let hTB = (progress * 16).toFixed(6) + 'vh';
        if(this.isMobile) {
            hTB = (progress * 32).toFixed(6) + 'vh';
        }

        const setLeft = gsap.quickSetter(".SectionPage__FrameBorderLeft", "translateX");
        const setTop = gsap.quickSetter(".SectionPage__FrameBorderTop", "translateY");
        const setRight = gsap.quickSetter(".SectionPage__FrameBorderRight", "translateX");
        const setBottom = gsap.quickSetter(".SectionPage__FrameBorderBottom", "translateY");
        const setHeroHeaderTitleOpacity = gsap.quickSetter(".SectionPage__HeroHeaderCatchphrase", "opacity");
        const setScrollOpacity = gsap.quickSetter(".SectionPage__ScrollCta", "opacity");

        setLeft("-" + hLR);
        setTop("-" + hTB);
        setRight(hLR);
        setBottom(hTB);

        setHeroHeaderTitleOpacity(1 - 2 * progress);
        setScrollOpacity(1 - 2 * progress);
    },

    init: function (context) {
        let {isDesktop, isMobile} = context.conditions;
        this.isDesktop = isDesktop;
        this.isMobile = isMobile;

        this.initScrollers();
    }
}
