import $ from 'jquery';
import _ from 'lodash';
import bootstrap from 'bootstrap';
import device from 'current-device';
import svg4everybody from 'svg4everybody';


import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);
gsap.defaults({ease: "none", duration: 2});

// Router/Pages
import router from "./utils/router.js";
import * as pages from "./pages.js";

// Animations
import * as animations from "./utils/animations.js";

// Cookies
import * as cookieconsent from "./cookieconsent-init.js";

window.jQuery = window.$ = $;

$(document).ready(function () {
    svg4everybody();

    const pageName = router.getPageName();
    if(pageName !== '') {
        const currentPage = pages[pageName] || {};

        if(!_.isEmpty(currentPage) && _.has(currentPage, 'initPage') && _.isFunction(pages[pageName].initPage)) {
            pages[pageName].initPage();
        } else {
            console.log('Page "' + pageName + '" not found!');
        }
    }

    // LAST
    animations.init();

    console.log(window.location.hash);
    // INIT HASH SCROLL
    const hash = window.location.hash;
    if(hash) {
        window.location.assign(window.location.href.replace(hash, ''));
        window.location.assign(window.location.href + hash);
    }

});
