const _ = require("lodash");

let db = {
    "brands": {
        "schiuker": {
            "name":"Schiuker",
            "note": "",
            "logo": "/img/brands/logo_schiuker.webp",
            "logo_alt": "logo Schiuker"
        },
        "k-line": {
            "name":"K-LINE",
            "note": "",
            "logo": "/img/brands/logo_kline.webp",
            "logo_alt": "logo KLine"
        },
        "gc-infissi": {
            "name":"GC Infissi",
            "note": "",
            "logo": "/img/brands/logo_gcinfissi.webp",
            "logo_alt": "logo GC"
        },
        "stark": {
            "name":"Stark",
            "note": "",
            "logo": "/img/brands/logo_stark.webp",
            "logo_alt": "logo Stark"
        },
        "teknika": {
            "name":"TEKNIKA",
            "note": "",
            "logo": "/img/brands/logo_teknika.webp",
            "logo_alt": "logo Teknika"
        },
        "oknokomp": {
            "name":"OKNOKOMP",
            "note": "",
            "logo": "/img/brands/logo_oknokomp.webp",
            "logo_alt": "logo Oknokomp"
        },
        "alunova": {
            "name":"ALUNOVA",
            "note": "",
            "logo": "/img/brands/logo_alunova.gif",
            "logo_alt": "logo Alunova"
        },
        "pergo": {
            "name":"PERGO",
            "note": "",
            "logo": "/img/brands/logo_pergo.webp",
            "logo_alt": "logo Pergo"
        },
        "mito": {
            "name":"MITO",
            "note": "",
            "logo": "/img/brands/logo_mito.webp",
            "logo_alt": "logo Mito"
        },
        "somfy": {
            "name":"SOMFY",
            "note": "",
            "logo": "/img/brands/logo_somfy.webp",
            "logo_alt": "logo Somfy"
        },
        "bettio": {
            "name":"BETTIO",
            "note": "",
            "logo": "/img/brands/logo_bettio.webp",
            "logo_alt": "logo Bettio"
        },
        "aip-sololegno": {
            "name":"AIP SOLOLEGNO",
            "note": "",
            "logo": "/img/brands/logo_sololegno.webp",
            "logo_alt": "logo aipsololegno"
        },
        "trix_porte": {
            "name":"TRIX PORTE",
            "note": "",
            "logo": "/img/brands/logo_trix.webp",
            "logo_alt": "trix porte"
        },
        "cristaldesign_visioni": {
            "name":"CristalDesign Visioni",
            "note": "",
            "logo": "/img/brands/logo_visioni.webp",
            "logo_alt": "trix porte"
        },
        "ke": {
            "name":"KE",
            "note": "",
            "logo": "/img/brands/logo_ke.webp",
            "logo_alt": "logo Ke"
        },
        "seraplastic": {
            "name":"SERAPLASTIC",
            "note": "",
            "logo": "/img/brands/logo_seraplastic.webp",
            "logo_alt": "logo Seraplastic"
        },
    },
    "products": [
        {
            "alt": "Prodotto A",
            "brand": "schiuker",
            "category_id": 1,
            "company_id": 1,
            "company": "Artem Serramenti",
            "content": "Descrizione Prodotto A",
            "id": 1,
            "image": "img/products/Porcelaingres.webp",
            "materials": "alluminio, pvc",
            "title": "Prodotto A",
            "warranty": "12 mesi"
        },
        {
            "alt": "Prodotto B",
            "brand": "schiuker",
            "category_id": 1,
            "company_id": 1,
            "company": "Artem Serramenti",
            "content": "Descrizione Prodotto B",
            "id": 2,
            "image": "img/products/Porcelaingres.webp",
            "materials": "alluminio, pvc",
            "title": "Prodotto B",
            "warranty": "A vita"
        },
        {
            "alt": "Prodotto C",
            "brand": "schiuker",
            "category_id": 1,
            "company_id": 1,
            "company": "Artem Serramenti",
            "content": "SCIUKER\nDa sempre il nostro punto di forza è l’istinto progettuale del Centro Ricerca e Sviluppo SciukerLab che, con equilibrio, riesce a coniugare la naturalezza dei materiali con l’artigianalità italiana senza rinunciare alle performance delle collezioni di finestre.",
            "id": 3,
            "image": "img/products/Porcelaingres.webp",
            "materials": "legno, alluminio",
            "title": "Prodotto C",
            "warranty": "12 mesi"
        },
        {
            "alt": "Prodotto D",
            "brand": "schiuker",
            "category_id": 1,
            "company_id": 1,
            "company": "Artem Serramenti",
            "content": "Descrizione Prodotto D",
            "id": 4,
            "image": "img/products/Porcelaingres.webp",
            "materials": "legno, alluminio",
            "title": "Prodotto D",
            "warranty": "24 mesi"
        },
        {
            "alt": "Prodotto E",
            "brand": "schiuker",
            "category_id": 2,
            "company_id": 2,
            "company": "Architetto Giacometti",
            "content": "Descrizione Prodotto E",
            "id": 5,
            "image": "img/products/Porcelaingres.webp",
            "materials": "",
            "title": "Prodotto E",
            "warranty": ""
        },
        {
            "alt": "Prodotto F",
            "brand": "schiuker",
            "category_id": 2,
            "company_id": 2,
            "company": "Architetto Giacometti",
            "content": "Descrizione Prodotto F",
            "id": 6,
            "image": "img/products/Porcelaingres.webp",
            "materials": "",
            "title": "Prodotto F",
            "warranty": ""
        },
        {
            "alt": "Prodotto G",
            "brand": "schiuker",
            "category_id": 3,
            "company_id": 3,
            "company": "Parquet Italia",
            "content": "Descrizione Prodotto G",
            "id": 7,
            "image": "img/products/Porcelaingres.webp",
            "materials": "legno acero",
            "title": "Prodotto G",
            "warranty": "6 mesi"
        },
        {
            "alt": "Prodotto H",
            "brand": "schiuker",
            "category_id": 3,
            "company_id": 3,
            "company": "Parquet Italia",
            "content": "Descrizione Prodotto H",
            "id": 8,
            "image": "img/products/Porcelaingres.webp",
            "materials": "Rovere naturale",
            "title": "Prodotto H",
            "warranty": "6 mesi"
        },
        {
            "alt": "Prodotto I",
            "brand": "schiuker",
            "category_id": 3,
            "company_id": 3,
            "company": "Parquet Italia",
            "content": "Descrizione Prodotto I",
            "id": 9,
            "image": "img/products/Porcelaingres.webp",
            "materials": "Multistrato Rovere miele",
            "title": "Prodotto I",
            "warranty": "6 mesi"
        },
        {
            "alt": "Prodotto J",
            "brand": "schiuker",
            "category_id": 3,
            "company_id": 3,
            "company": "Parquet Italia",
            "content": "Descrizione Prodotto J",
            "id": 10,
            "image": "img/products/Porcelaingres.webp",
            "materials": "Rovere noce rustico",
            "title": "Prodotto J",
            "warranty": "6 mesi"
        },
        {
            "alt": "Prodotto K",
            "brand": "schiuker",
            "category_id": 4,
            "company_id": 4,
            "company": "Domotica SRL",
            "content": "Descrizione Prodotto K",
            "id": 11,
            "image": "img/products/sp-4766-hometouch.webp",
            "materials": "",
            "title": "Prodotto K",
            "warranty": "24 mesi"
        }
    ],
    "categories": [
        {
            "id": 1,
            "name": "Serramenti",
            "image": "img/categories/schiuker/schiuker_Exo_4.webp",
            "image_alt": "schiuker",
            "brands": [ "schiuker" ],
            "note": "Serramenti legno alluminio note",
            "href": "/categories/serramenti",
        },
        /*{
            "id": 2,
            "name": "Serramenti allumnio",
            "image": "img/categories/kline/serramenti-alluminio.webp",
            "image_alt": "image alluminio",
            "brands": [ "k-Line" ],
            "note": "Serramenti alluminio note",
            "href": "#",
        },
        {
            "id": 3,
            "name": "Serramenti pvc",
            "image": "img/categories/gc/okulta.webp",
            "image_alt": "image pvc",
            "brands": [ "gc-infissi" ],
            "note": "Serramenti pvc note",
            "href": "#",
        },*/
        {
            "id": 5,
            "name": "Porte interne",
            "image": "img/categories/aip_sololegno/sololegno_Reset_RS6-I_RS6-V_Pag.40.webp",
            "image_alt": "aip sololegno",
            "brands": [ "aip-sololegno", "trix_porte", "cristaldesign_visioni" ],
            "note": "Porte interne note",
            "href": "/categories/porte",
        },
        {
            "id": 8,
            "name": "Persiane e Scuri",
            "image": "img/categories/alunova/alunova_milano_1.webp",
            "image_alt": "alunova",
            "brands": [ "alunova", "oknokomp" ],
            "note": "Scuri",
            "href": "/categories/scuri",
        },
        {
            "id": 4,
            "name": "Blindati",
            "image": "img/categories/stark/stark-project_01.webp",
            "image_alt": "stark",
            "brands": [ "stark" ],
            "note": "blindati note",
            "href": "/categories/blindati",
        },
        {
            "id": 10,
            "name": "Pavimenti",
            "image": "img/categories/pergo/pergo-wood-lofoten.webp",
            "image_alt": "pergo",
            "brand": [ "pergo" ],
            "note": "Pavimenti note",
            "href": "/categories/pavimenti",
        },
        /*{
            "id": 9,
            "name": "Scuri Alluminio",
            "image": "img/categories/alunova/alunova_Scuretto-TT-esempio.webp",
            "image_alt": "image scuri alluminio",
            "brands": [ "alunova" ],
            "note": "Scuri alluminio note",
            "href": "#",
        },*/
        {
            "id": 6,
            "name": "Grate, Avvolgibili, Frangisole e Zanzariere",
            "image": "img/categories/bettio/bettio_estetika-motorizzata-incasso.webp",
            "image_alt": "bettio",
            "brands": [ "bettio" ],
            "note": "Zanzariere note",
            "href": "/categories/zanzariere",

        },
        /*{
            "id": 7,
            "name": "Tapparelle/Monoblocchi",
            "image": "img/categories/teknika/teknika_ASD55.webp",
            "image_alt": "image tapparelle",
            "brands": [ "teknika" ],
            "note": "Tapparelle note",
            "href": "#",
        },*/


        {
            "id": 11,
            "name": "Domotica",
            "image": "img/categories/somfy/somfy_Banner-TaHoma-switch2-new1.webp",
            "image_alt": "somfy",
            "brands": [ "somfy" ],
            "note": "Domotica note",
            "href": "/categories/domotica",
        },
        /*{
            "id": 12,
            "name": "Grate",
            "image": "img/categories/mito/galleria_lumia1-scaled.webp",
            "image_alt": "image grate",
            "brands": [ "mito", ],
            "note": "Grate note",
            "href": "#",
        },*/
        {
            "id": 13,
            "name": "Tende da sole",
            "image": "img/categories/ke/ke_QUBICA-PLUMB_LOW_01_low.webp",
            "image_alt": "ke",
            "brands": [ "ke", "seraplastic" ],
            "note": "Tende da sole note",
            "href": "/categories/tende",
        },


    ],
    "companies": [
        {
            "id": 1,
            "company": "Artem Serramentiiiii",
            "address": "via Locchi, 2",
            "cap": "37124",
            "email": "info@artemserramenti.it",
            "phone": "1234567890",
            "town": "Verona",
            "web": "www.artemserramentiiiii.it"
        },
        {
            "id": 2,
            "company": "Architetto Giacometti",
            "address": "via Architetto, 2",
            "cap": "37126",
            "email": "info@prova.it",
            "phone": "1234567890",
            "town": "Verona",
            "web": "www.atnt1.it"
        },
        {
            "id": 3,
            "company": "Parquet Italia",
            "indirizzo": "via Parquet, 2",
            "cap": "37127",
            "email": "info@prova.it",
            "phone": "1234567890",
            "town": "Verona",
            "web": "www.atnt2.it"
        },
        {
            "id": 4,
            "company": "Domotica SRL",
            "address": "via Domotica, 2",
            "cap": "37125",
            "email": "info@prova.it",
            "phone": "1234567890",
            "town": "Verona",
            "web": "www.atnt3.it"
        }
    ],

    "legno_alluminio" : {
        id: "serramenti_legno_alluminio",
        title: "Legno Alluminio",
        brands: ["schiuker"],
        materials: "Materiali",
        main_description: "Schiuker Frames produce serramenti integrati in legno alluminio. La sostenibilità rappresenta il valore fondante di questa azienda che utilizza legno proveniente solo da foreste certificate PEFC e FSC.<br> Prima azienda di finestre quotata in Borsa Italiana.",
        note: "Note category legno alluminio",
        guaranty: "tipi di garanzia",
        images: [
            {
                id:1,
                image:"/img/categories/schiuker/schiuker_stratek_1.webp",
                alt:"schiuker stratek"
            },
            {
                id:2,
                image:"/img/categories/schiuker/schiuker_skill_MIAMI INTERNO.webp",
                alt:"schiuker skill"
            },
            {
                id:3,
                image:"/img/categories/schiuker/schiuker_skill_ROMA.webp",
                alt:"schiuker skill"
            },
            {
                id:4,
                image:"/img/categories/schiuker/schiuker_skill_SINGAPORE.webp",
                alt:"schiuker skill"
            },
            {
                id:4,
                image:"/img/categories/schiuker/schiuker_isik_3.webp",
                alt:"schiuker isik"
            },
            {
                id:4,
                image:"/img/categories/schiuker/schiuker_Exo_4.webp",
                alt:"schiuker exo"
            },
        ],
        evidence_image:{
            image:"/img/categories/schiuker/schiuker_skill_AmbienteMI_ESTERNO.webp",
            alt:"schiuker skill"
        },
        evidence_text: "L’intera collezione, esclusiva <b>Sciuker LAB</b>, è oggetto di due brevetti. <br><br>Il primo, la <b>tecnologia Legatec Overlap Thermal Profile</b>, riguarda l’applicazione termoprofilata sul legno esterno di una lamina in lega di alluminio protetta da uno strato termostatico isolante e da un film esterno a base di resina acrilica ad alte prestazioni. <br>Questa tecnologia permette di proteggere il legno dagli agenti atmosferici e di conservare la lucentezza del serramento. <br><br>Il secondo, è relativo all’accoppiamento angolare delle ante, a 90° all’esterno e a 45° all’interno, che rievoca lo stile delle tradizionali finestre in legno e quindi rende gli infissi di questa collezione ideali per le ristrutturazioni anche nei centri storici."
    },

    "alluminio" : {
        id: "serramenti_alluminio",
        title: "Alluminio",
        brands: ["k-line"],
        materials: "Materiali",
        main_description: "<b>K•LINE</b> è stata il primo produttore di serramenti ad offrire al mercato la finestra ad ante invisibili. <br>Grazie ad un’idea innovativa che tiene conto di tutti gli aspetti energetici di un serramento la finestra K•LINE si è sempre distinta per le sue prestazioni termiche ed i suoi apporti solari.<br><br> K•LINE ha imposto sul mercato un nuovo standard di finestra: l’anta invisibile bicolore.",
        note: "Note category legno alluminio",
        guaranty: "tipi di garanzia",
        images: [
            {
                id:1,
                image:"/img/categories/kline/kline_anta_nascosta_1.webp",
                alt:"kline"
            },
            {
                id:1,
                image:"/img/categories/kline/kline_anta_nascosta_2.webp",
                alt:"kline"
            },
            {
                id:1,
                image:"/img/categories/kline/kline-klfp-producto3b.webp",
                alt:"kline"
            }
        ],
        evidence_image:{
            image:"/img/categories/kline/kline-klfp-producto4b.webp",
            alt:"kline"
        },
        evidence_text: "L’alluminio è naturalmente uno dei materiali che più rispettano l’ambiente, nella totalità del suo ciclo di vita. <br>È ottenuto dalla bauxite, terza risorsa naturale del pianeta, ed il 50% dell’energia elettrica utilizzata per la sua trasformazione e lavorazione è di natura idraulica.<br><Infine, è un materiale riciclabile al 100% senza alcuna perdita di proprietà. La filiera di riciclaggio dell’alluminio oggi reimpiega il 95% dell’alluminio utilizzato per l’edilizia.<br><br>Resistenza, leggerezza, malleabilità, riciclabilità… le qualità dell’alluminio sono numerose e rispondono in maniera precisa alle esigenze dello sviluppo sostenibile in termini di qualità di costruzione. Le proprietà naturali dell’alluminio lo rendono un materiale sano e sicuro, che non emette né polveri, né vapori, né particelle, e non presenta alcuna tossicità al contatto.<br><br> Ideale per preservare la qualità dell’aria all’interno, si integra all’habitat senza produrre emissioni nocive, nemmeno in caso di incendio."
    },

    "pvc" : {
        id: "serramenti_pvc",
        title: "PVC",
        brands: ["gc-infissi"],
        materials: "Materiali",
        main_description: "<b>GC INFISSI</b> è stata la prima azienda in Italia ad utilizzare macchinari che consentono una saldatura V-perfect, per rendere i serramenti in PVC dei veri capolavori, senza mai trascurare funzionalità e durata del prodotto.<br>Per rispettare i più alti requisiti costruttivi, su tutti i serramenti vengono utilizzati vetri basso emissivi selettivi con gas Argon e canalina Warm Edge.",
        note: "Note category legno alluminio",
        guaranty: "tipi di garanzia",
        images: [
            {
                id:1,
                image:"/img/categories/gc/gc_astra_1.webp",
                alt:"gc astra"
            },
            {
                id:1,
                image:"/img/categories/gc/gc_therma_1.webp",
                alt:"gc therma"
            },
            {
                id:1,
                image:"/img/categories/gc/gc_okulta_1.webp",
                alt:"alt home"
            }
        ],
        evidence_image:{
            image:"/img/categories/gc/gc_scorrevole_alzante_1.webp",
            alt:"gc"
        },
        evidence_text: "Sicurezza, durabilità, resistenza: i migliori prodotti nascono dalla passione per la qualità e dal desiderio di fornire un servizio eccellente.<br><br>Un impegno orientato alla ricerca e allo sviluppo del prodotto e a mantenerne elevati standard qualitativi. Una flessibilità del pensiero aziendale che traccia percorsi coerenti con le esigenze del mercato.<br><br><b>GC Infissi</b> propone materie prime di altissima qualità, Made in Italy,assistenza alla clientela e prodotti eco-friendly, utilizzando le nostre energie per una ricerca continua dell’eccellenza. <br><br>A questi aggiunge un approccio di pensiero ponderato e strategico che prende le mosse dalla filosofia giapponese Kaizen."
    },

    "porte_interne_sololegno" : {
        id: "porte_interne_sololegno",
        title: "Sololegno",
        brands: ["aip-sololegno"],
        materials: "Materiali",
        main_description: "Impegnati nella ricerca di soluzioni dal ridotto impatto ambientale presentiamo la linea <b>Aip Sololegno</b>, la cui filosofia 'green' si completa anche per l'utilizzo di vernici provenienti da fornitori alltrettanto attenti all'ambiente.",
        note: "Note category legno alluminio",
        guaranty: "tipi di garanzia",
        images: [
            {
                id:1,
                image:"/img/categories/aip_sololegno/PRESTIGE_PG3_1.webp",
                alt:"aip sololegno"
            },
            {
                id:2,
                image:"/img/categories/aip_sololegno/Reset_RS4_1.webp",
                alt:"aip sololegno"
            },
            {
                id:3,
                image:"/img/categories/aip_sololegno/SINTESI_MD21_1.webp",
                alt:"aip sololegno"
            },
            {
                id:4,
                image:"/img/categories/aip_sololegno/CONCERTO_LP32_2.webp",
                alt:"aip sololegno"
            },
            {
                id:5,
                image:"/img/categories/aip_sololegno/STILIDEA_1.webp",
                alt:"aip sololegno"
            }
        ],
        evidence_image:{
            image:"/img/categories/aip_sololegno/sololegno_Reset_RS6-I_RS6-V_Pag.40.webp",
            alt:"aip sololegno"
        },
        evidence_text: "Le unicità del legno si esaltano dall’incontro con la maestria e l’esperienza ultra decennale dell’azienda <b>AIP</b> che, coerente con la sua filosofia green, utilizza solo colori naturali e tinte all’acqua capaci di esaltare le venature della materia prima senza arrecare danno all’ambiente. Grazie ad additivi e ioni d’argento contenuti nelle vernici si combatte con efficacia la proliferazione di batteri, schermando microbiologicamente tutta la porta. Un’ulteriore importante tappa del percorso che l’azienda in questi anni ha intrapreso per dare il suo contributo concreto alla cura del pianeta e preservarlo per le prossime generazioni."
    },

    "porte_interne_trix" : {
        id: "porte_interne_trix",
        title: "Trix",
        brands: ["trix_porte","aip-sololegno"],
        materials: "Materiali",
        main_description: "La nostra proposta presenta soluzioni che spaziano dalle linee essenziali a quelle più ricercate, aperture adatte ad ogni esigenza, prevede l’uso di elementi in vetro e di inserti in alluminio, spazia a finiture e gamma di colori in grado di soddisfare il gusto di tutti.",
        note: "Note category legno alluminio",
        guaranty: "tipi di garanzia",
        images: [
            {
                id:1,
                image:"/img/categories/aip_trix/trix_cemento_1.webp",
                alt:"aip trix"
            },
            {
                id:2,
                image:"/img/categories/aip_trix/trix_GF24_NoceSahara_1.webp",
                alt:"aip trix"
            },
            {
                id:3,
                image:"/img/categories/aip_trix/trix_GF01_RovereCuoio_1.webp",
                alt:"aip trix"
            },
        ],
        evidence_image:{
            image:"/img/categories/aip_trix/vertix_VT15_BiancoSoft_1.webp",
            alt:"aip trix"
        },
        evidence_text: "Riconosciuto e apprezzato in tutto il mondo, il “Made in Italy” è valore aggiunto anche per le porte <b>Trix</b> che nascono tutte a Barge, in provincia di Cuneo, nello stabilimento di AIP ai piedi del Monviso.<br><br>Sono di produzione nazionale anche tutti i materiali e le componenti, dagli accessori alle maniglie, delle diverse collezioni. Perché sulla bellezza e sulla qualità non si transige, ma neppure sulla funzionalità che ogni porta Trix deve garantire per rappresentare con orgoglio tutto il gusto e lo stile italiano."
    },

    "porte_interne_visioni" : {
        id: "porte_interne_visioni",
        title: "Visioni",
        brands: ["cristaldesign_visioni", "aip-sololegno"],
        materials: "Materiali",
        main_description: "Il <b>vetro</b> è presentato in sette collezioni: Trasparenza, Linea, Natura, Motivi, Tendenza, Riflesso e Incontro. Ciascuna sviluppa concetti differenti, con la volontà di valorizzare le molteplici proprietà del vetro e di adattarle all’evoluzione del design contemporaneo.",
        note: "Note category legno alluminio",
        guaranty: "tipi di garanzia",
        images: [
            {
                id:1,
                image:"/img/categories/aip_visioni/vetro_ln21_1.webp",
                alt:"cristaldesign visioni"
            },
            {
                id:2,
                image:"/img/categories/aip_visioni/vetro_V17_1.webp",
                alt:"cristaldesign visioni"
            },
            {
                id:3,
                image:"/img/categories/aip_visioni/vetro_tr01_grigio.webp",
                alt:"cristaldesign visioni"
            },
            {
                id:3,
                image:"/img/categories/aip_visioni/vetro_MV09_1.webp",
                alt:"cristaldesign visioni"
            }
        ],
        evidence_image:{
            image:"/img/categories/aip_visioni/vetro_tr01_extrachiaro.webp",
            alt:"cristaldesign visioni"
        },
        evidence_text: "Ante vetrate semplici, uniformi e prive di “fronzoli”,  l’essenziale non è sempre invisibile agli occhi."
    },

    "portoncini_blindati" : {
        id: "portoncini_blindati",
        title: "Portoncini",
        brands: ["stark"],
        materials: "Materiali",
        main_description: "Tutte le porte blindate <b>STARK</b> sono certificate secondo le normative europee antieffrazione che le classifica secondo il loro grado di resistenza allo scasso. Il grado di sicurezza della porta è acquisito in base al superamento di prove di scasso altamente selettive realizzate da preposti laboratori ed istituti di certificazione ufficialmente riconosciuti.",
        note: "Note category portoncini blindati",
        guaranty: "tipi di garanzia",
        images: [
            {
                id:1,
                image:"/img/categories/stark/stark_reset_1.webp",
                alt:"strak reset"
            },
            {
                id:1,
                image:"/img/categories/stark/stark_techline_1.webp",
                alt:"stark techline"
            },
            {
                id:1,
                image:"/img/categories/stark/stark-project_01.webp",
                alt:"stark project"
            }
        ],
        evidence_image:{
            image:"/img/categories/stark/gallery-exclusive_01.webp",
            alt:"stark exclusive"
        },
        evidence_text: "Non solo sicurezza, le porte blindate Stark sono costruite per garantire il maggior potere fono-isolante e raggiungono alti valori di abbattimento acustico grazie all'alta capacità di isolare l'interno di un ambiente dal rumore proveniente dall'esterno.<br><br>L'attenzione è riservata anche all'isolamento termico, che si traduce in: <br><br>• Minore inquinamento atmosferico <br>• Maggiore confort abitativo <br>• Riduzione dei consumi energetici <br>• Risparmio economico."
    },

    "scuri_alunova" : {
        id: "porte_interne_visioni",
        title: "Persiane in Alluminio",
        brands: ["alunova"],
        materials: "Materiali",
        main_description: "La gamma di persiane e scuretti “Novashutters” di <b>Alunova</b> si rivolge a tutte le esigenze richieste dal mercato, con particolare attenzione alle attuali linee moderne e ricercate, senza sottovalutare il contesto storico che le varie zone d’Italia richiedono.",
        note: "Note category legno alluminio",
        guaranty: "tipi di garanzia",
        images: [
            {
                id:1,
                image:"/img/categories/alunova/alunova_milano_1.webp",
                alt:"alunova"
            },
            {
                id:2,
                image:"/img/categories/alunova/alunova_trento_1.webp",
                alt:"alunova"
            },
            {
                id:3,
                image:"/img/categories/alunova/alunova_venezia_1.webp",
                alt:"alunova"
            },
        ],
        evidence_image:{
            image:"/img/categories/alunova/alunova_Scuretto-TT-esempio.webp",
            alt:"alunova"
        },
        evidence_text: "I materiali utilizzati per la costruzione delle persiane Alunova seguono test rigorosi e ricorrenti per garantire una qualità continuativa. L’alluminio utilizzato è composto da una lega EN AW- 6060 a stato fisico T5 o T6." +
            "<br>" +
            "Tutti gli accessori e le guarnizioni sono realizzati in materiali resistenti alle condizioni atmosferiche più difficili e garantiscono una durata nel tempo molto elevata.<br><br>" +
            "Le linee di verniciatura e decorazione seguono standard elevatissimi e rispettano la totalità delle norme vigenti in termini ambientali, di sicurezza e di qualità.",
    },

    "scuri_oknokomp" : {
        id: "porte_interne_visioni",
        title: "Materiale composito",
        brands: ["oknokomp",],
        materials: "Materiali",
        main_description: "Le persiane e gli scuri <b>Oknokomp</b> sono realizzati con materiale composito costituito da fibre di vetro indurite con una speciale resina e irrigidito con fibre di kevlar. La particolare composizione di questo materiale garantisce a persiane e scuri un’eccezionale resistenza meccanica accompagnata da un peso ridotto e un’elevata resistenza ai raggi solari",
        note: "Note category legno alluminio",
        guaranty: "tipi di garanzia",
        images: [
            {
                id:1,
                image:"/img/categories/oknokomp/oknokomp_fissa_composito_1.webp",
                alt:"oknokomp composito"
            },
            {
                id:2,
                image:"/img/categories/oknokomp/oknokomp_lamelle_orientabili_1.webp",
                alt:"oknokomp composito"
            },
            {
                id:3,
                image:"/img/categories/oknokomp/oknokomp_padovane_composito_1.webp",
                alt:"oknokomp composito"
            },
            {
                id:3,
                image:"/img/categories/oknokomp/oknokomp_scuro_1.webp",
                alt:"oknokomp composito"
            }
        ],
        evidence_image:{
            image:"/img/categories/oknokomp/oknokomp_evidence.webp",
            alt:"oknokomp composito"
        },
        evidence_text: "Scrupolosa selezione dei fornitori di materiali e accessori, con il preciso intento di unire resistenza e stabilità dei prodotti alla cura del design.<br><br>I prodotti sono stati sottoposti a severe verifiche, che hanno ottenuto la certificazione CE."
    },

    "pavimenti" : {
        id: "pavimenti",
        title: "Parquet, laminato vinile",
        brands: ["pergo"],
        materials: "Materiali",
        main_description: "Un approccio orientato al futuro significa evitare il più possibile l’uso di nuove materie prime. Ecco perché il pannello in legno alla base del pavimento in laminato e legno <b>Pergo</b> è prodotto interamente con legno di recupero. Questo prodotto di scarto del legno viene raccolto da segherie, processi di gestione forestale sostenibile, manutenzione stradale … e finirebbe altrimenti nell’inceneritore o nella discarica.",
        note: "Note category legno alluminio",
        guaranty: "tipi di garanzia",
        images: [
            {
                id:1,
                image:"/img/categories/pergo/pergo_parquet_rovere_grigio.webp",
                alt:"pergo"
            },
            {
                id:2,
                image:"/img/categories/pergo/pergo_parquet_rovere_naturale_1.webp",
                alt:"pergo"
            },
            {
                id:3,
                image:"/img/categories/pergo/pergo_parquet_rovere_tenue_1.webp",
                alt:"pergo"
            },
            {
                id:3,
                image:"/img/categories/pergo/pergo_vinyl_rovere_1.webp",
                alt:"pergo"
            }
        ],
        evidence_image:{
            image:"/img/categories/pergo/pergo_parquet_rovere_tenue_1.webp",
            alt:"pergo"
        },
        evidence_text: "I nostri pavimenti sono compatibili con il riscaldamento a pavimento.<br><br>Questa è la filosofia che ci guida nella scelta dei pavimenti in parquet che proponiamo alla nostra clientela. <br><br>L’aspetto di un pavimento è sempre stato importante quanto la capacità di resistere alla vita quotidiana, per questo la nostra scelta è caduta sulla linea <b>Pergo</b>."
    },

    "zanzariere" : {
        id: "zanzariere",
        title: "Zanzariere",
        brands: ["bettio", "teknika"],
        materials: "Materiali",
        main_description: "Alto valore estetico e funzionale per una efficace barriera contro gli insetti più fastidiosi.<br><br> La componentistica è garantita per 10 anni, per ottenere informazioni dettagliate rivolgersi al perosnale di Progetto Casa.",
        note: "Note category legno alluminio",
        guaranty: "tipi di garanzia",
        images: [
            {
                id:2,
                image:"/img/categories/bettio/bettio_picoscenica.webp",
                alt:"bettio"
            },
            {
                id:3,
                image:"/img/categories/bettio/bettio_living-2.webp",
                alt:"bettio"
            },
            {
                id:1,
                image:"/img/categories/bettio/bettio_lisa-pf-ext.webp",
                alt:"bettio"
            },

        ],
        evidence_image:{
            image:"/img/categories/bettio/bettio_zanzariere_1.webp",
            alt:"bettio"
        },
        evidence_text: "Abbiamo a disposizione diversi tipi di zanzariere, tra cui:<br><br>1. Zanzariere fisse, montate in modo permanente su finestre o porte e non possono essere aperte o chiuse.<br><br>2. Zanzariere a battente, dotate di una cerniera o di un sistema di apertura a scatto che permette di aprirle e chiuderle come una porta normale. Sono ideali per l'accesso a balconi o terrazze.<br><br>3. Le zanzariere scorrevoli sono simili alle zanzariere a battente, ma si aprono e si chiudono scorrendo lateralmente su una guida. Sono spesso utilizzate per porte finestre o per aperture di grandi dimensioni.<br><br>4. Le zanzariere a rullo si arrotolano quando non sono in uso."
    },

    "tapparelle" : {
        id: "tapparelle",
        title: "Avvolgibili e frangisole",
        brands: ["teknika"],
        materials: "Materiali",
        main_description: "Profili in pvc rinforzato con fibra di vetro, acciaio con schiuma poliuretanica a media densità e alluminio coibentato con schiuma poliuretanica a media ed alta densità,  modelli con feritoie larghe che permettono un maggiore filtraggio di luce naturale.<br><br>Queste sono alcune delle proposte per avvolgibili ad alta tecnologia offerte grazie alla collaborazione con <b>Teknika</b>.",
        note: "Note category legno alluminio",
        guaranty: "tipi di garanzia",
        images: [
            {
                id:2,
                image:"/img/categories/teknika/teknika_a39.webp",
                alt:"teknika"
            },
            {
                id:3,
                image:"/img/categories/teknika/teknika_a45.webp",
                alt:"teknika"
            },
            {
                id:1,
                image:"/img/categories/teknika/teknika_duero.webp",
                alt:"teknika"
            },

        ],
        evidence_image:{
            image:"/img/categories/teknika/teknika_inklina.webp",
            alt:"teknika"
        },
        evidence_text: "Tende frangisole con lamelle in allumio ad argano o con motore disponibile anche per grandi superfici.<br><br>Progetto Casa ti aiuta a curare ogni aspetto della tua abitazione. "
    },

    "grate" : {
        id: "grate",
        title: "Grate",
        brands: ["mito"],
        materials: "Materiali",
        main_description: "Inferriate in acciaio con classe di infrazione 3, inferriate scorrevoli altamente personalizzabili e tapparelle in alluminio con meccanismo autobloccante certificato con ottima resistenza a intemperie di qualsiasi entità, sono alcune delle proposte di <b>Mito</b>. ",
        note: "Note category grate",
        guaranty: "tipi di garanzia",
        images: [
            {
                id:2,
                image:"/img/categories/mito/mito_Flexa01.webp",
                alt:"mito"
            },
            {
                id:3,
                image:"/img/categories/mito/mito_Flexa02.webp",
                alt:"mito"
            },
            {
                id:1,
                image:"/img/categories/mito/mito_Dea_Elle.webp",
                alt:"mito"
            },
            {
                id:4,
                image:"/img/categories/mito/mito_Dea_Silver.webp",
                alt:"mito"
            },
            {
                id:5,
                image:"/img/categories/mito/mito_Dea_Elle.webp",
                alt:"mito"
            },

        ],
        evidence_image:{
            image:"/img/categories/mito/mito_inferriate_lumia_3.webp",
            alt:"mito"
        },
        evidence_text: "Disponible con anta unica o doppia, ante asimmetriche o con doppia serratura.<br>L'applicazione in aderenza al vano luce non necessita di opere murarie, mentre con l'eventuale installazione di un controtelaio è possibile realizzare lo scorrimento interno permettendo la completa sparazione dell'infisso.<br><br>Nostante sia possibile scegliere tra 14 colorazioni standard proposte a catalogo, ogni inferriata è verniciabile con colorazioni personalizzate a seconda delle esigenze."
    },

    "domotica" : {
        id: "domotica",
        title: "TaHoma box",
        brands: ["somfy"],
        materials: "Materiali",
        main_description: "L'obiettivo di una smart home è migliorare i livelli di comfort, sicurezza e risparmio energetico dei suoi abitanti.<br><br>Gestire la temperatura, l’illuminazione, la videosorveglianza via remoto, automatizzare cancelli e garage movimentare tapparelle e persiane, aiuta a rendendere la casa un luogo più confortevole.",
        note: "Note category legno alluminio",
        guaranty: "tipi di garanzia",
        images: [
            {
                id:1,
                image:"/img/categories/somfy/somfy_compatibilty_1.webp",
                alt:"somfy"
            },
            {
                id:1,
                image:"/img/categories/somfy/somfy_my_home_1.webp",
                alt:"somfy"
            },
            {
                id:1,
                image:"/img/categories/somfy/somfy_Shoot_Savigny_2022_Vanessa_Andrieux_HD_450.webp",
                alt:"somfy"
            }
        ],
        evidence_image:{
            image:"/img/categories/somfy/somfy_smart-control-tahoma-switch-ambiance.webp",
            alt:"somfy"
        },
        evidence_text: "TaHoma box di <b>Somfy</b> è il sistema per una casa connessa che, sfruttando la comunicazione radio, non richiede alcuna modifica strutturale all'edificio e permette di gestire oltre 200 dispositivi connessi sia da casa, sia a distanza, attraverso la rete internet di smartphone, tablet e PC.<br><br>L'utilizzo di questo sistema permette la creazione di scenari personalizzati in funzione dei sensori intelligenti o programmando specifiche funzioni in orari prestabiliti."
    },

    "tende_da_sole" : {
        id: "tende_da_sole",
        title: "Tende da sole",
        brands: ["ke"],
        materials: "Materiali",
        main_description: "",
        note: "Note category legno alluminio",
        guaranty: "tipi di garanzia",
        images: [
            {
                id:1,
                image:"/img/categories/ke/ke_pergola_1.webp",
                alt:"ke"
            },
            {
                id:1,
                image:"/img/categories/ke/ke_QUBICA-PLUMB_LOW_01_low.webp",
                alt:"ke"
            },
            {
                id:1,
                image:"/img/categories/ke/ke_tende_da_sole_1.webp",
                alt:"ke"
            },
            {
                id:1,
                image:"/img/categories/ke/ke_tende_da_sole_2.webp",
                alt:"ke"
            }
        ],
        evidence_image:{
            image:"/img/categories/ke/ke_tende_da_sole_3.webp",
            alt:"ke"
        },
        evidence_text: ""
    },



}

let slogans = {
    serramenti: {
        bottom_separator: false,
        top_separator: false,
        animated: true,
        title: "Progetto Casa ha il serramento che fa per te.",

    },

    porte_interne: {
        bottom_separator: false,
        top_separator: false,
        animated: true,
        title: "Passa a trovarci nel nostro show-room.",

    },

    scuri: {
        bottom_separator: false,
        top_separator: false,
        animated: true,
        title: "Resistenza, stabilità e cura del design.",

    },

    domotica: {
        bottom_separator: false,
        top_separator: false,
        animated: true,
        title: "Ti aiutiuamo a rendere la casa più \"intelligente\".",

    },

    pavimenti: {
        bottom_separator: false,
        top_separator: false,
        animated: true,
        title: "Resistenza, stabilità e cura del design.",

    },

    portoncini_blindati: {
        bottom_separator: false,
        top_separator: false,
        animated: true,
        title: "Progetto Casa pensa anche alla sicurezza.",

    },

    tende: {
        bottom_separator: false,
        top_separator: false,
        animated: true,
        title: "Ogni progetto viene realizzato su misura in base alla location, sia che si tratti di piccolo terrazzo o di grande giardino.",

    },

    zanzariere: {
        bottom_separator: false,
        top_separator: false,
        animated: true,
        title: "Standard qualitativi sempre più alti e personalizzabili.",

    }
}

let data = {
    title: "Catalogo",
    db: db,
    slogans: slogans,
    serramenti: {
        title: "Serramenti"
    },
    porte_interne: {
        title: "Porte Interne"
    },
    portoncini_blindati: {
        title: "Blindati"
    },
    scuri: {
        title: "Persiane e Scuri"
    },
    pavimenti: {
        title: "Pavimenti"
    },

    tende: {
        title: "Tende da sole"
    },
    zanzariere: {
        title: "Grate, Avvolgibili, Frangisole e Zanzariere"
    },
    domotica: {
        title: "Domotica"
    },

    getCategory: function (id) {
        return _.find(db.categories, {id:id});
    },
    getCompany: function (id) {
        return _.find(db.companies, {id:id});
    },
    getProduct: function (id) {
        return _.find(db.products, {id:id});
    },
    getBrand: function (id) {
        return _.find(db.brands, {id:id});
    },

};

module.exports = data;


