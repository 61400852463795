import {gsap} from "gsap";
import $ from "jquery";
import * as partners from "../../views/data/pages/partners.cjs"
import * as location from "../utils/location.js";

export function initPage() {
    console.log('INIT PAGE: partners');

    // SEARCH
    let timeout = {};
    const input = $(".DealerLocator__SearchInput");
    input.on("input", function () {
        clearTimeout(timeout);
        timeout = setTimeout(searchPartner, 1000);
    });

    // DETAILS
    $('.Partners__ListItem').on('click', function (event) {
        event.stopPropagation();
        togglePartnerDetail(true, $(this).data("partner_id"), $(this).data("img_path"));
    });

    $('#partners_look').on('click', function (event) {
        event.stopPropagation();
        togglePartnerDetail(false);
    });

    $('.SlidingPanel__Close').on('click', function (event) {
        event.stopPropagation();
        togglePartnerDetail(false);
    });

    //MAP
    location.initMap();

    location.createMarkers(partners.db.partners);
}

let showDetail = false;

function togglePartnerDetail(val = showDetail, partner_id = -1, img_path = '') {
    let showDetail = val;
    let timeout = 0;
    const $sl = $('#slidingpanel__partner');
    const $body = $('body');
    if (showDetail && $sl.hasClass('isOpen')) {
        $sl.removeClass('isOpen');
        $body.removeClass('disableScroll');
        timeout = 700;
    }
    setTimeout(() => {

        if (showDetail) {
            $sl.addClass('isOpen');
            $body.addClass('disableScroll');
        } else {
            if ($sl.hasClass('isOpen')) {
                $sl.removeClass('isOpen');
                $body.removeClass('disableScroll');
            }
        }

        preparePartnerDetailData(partner_id, img_path);
    }, timeout);

}

function preparePartnerDetailData(partner_id, img_path) {

    if (partner_id === -1) {
        return;
    }

    const p = partners.partner(partner_id);

    // title
    $('#partner_detail_title').text(p.name);
    // brand
    $('#partner_detail_brand').text(p.brand);
    // brand logo
    let $img = $('#partner_detail_brand_logo');
    $img.attr('alt', p.brand_alt);
    $img.attr('src', img_path + '/' + p.brand_logo);
    // company
    //$('#partner_detail_company').text(p.company);
    // description
    $('#partner_detail_description').text(p.description);
    // image
    $img = $('#partner_detail_img')
    $img.attr('alt', p.alt);
    $img.attr('src', img_path + '/' + p.image);

    // materials
    $('#partner_detail_materials').text(p.materials);
    // warranty
    $('#partner_detail_warranty').text(p.warranty);

    $('#partner_detail_address').text(p.address + ' ' + p.cap + ' ' + p.town);
    $('#partner_detail_email').text(p.email);
    $('#partner_detail_web').text(p.web);
    $('#partner_detail_phone').text(p.phone);
}

function searchPartner() {

    let searchText = $('.DealerLocator__SearchInput').val().toLowerCase();
    $(".DealerLocator__Search").addClass("isSearching");

    $(".DealerLocator__SearchResults").find('.DealerLocator__SearchResult').each(function () {
        let id = $(this).data("partner_id");
        let partner = partners.partner(id);
        if (partner.town.toLowerCase().includes(searchText) || partner.cap.toLowerCase().includes(searchText)) {
            $(this).show();
            partner.visible = true;
        } else {
            $(this).hide();
            partner.visible = false;
        }
    });

    location.createMarkers(partners.db.partners);
}