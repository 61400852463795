import {gsap} from "gsap";
// Sticky reveal
import * as reveal from "../utils/sticky_reveal.js";
// Snapping
import * as snapping from "../utils/snapping.js";

export function initPage() {
    console.log('INIT PAGE: standard');
    reveal.init();
    snapping.init();
}


