const _ = require("lodash");

let db = {
    "title": "I nostri Partner",
    "title_search": "Trova un partner",
    "placeholder": "Città o Cap",
    "partners": [
        {
            "id": 1,
            "name": "Artem Serramenti & Design",
            "address": "Via Locchi 4",
            "cap": "37124",
            "town": "Verona",
            "country": "Italia",
            "web": "www.artemserramenti.it",
            "email": "info@artemserramenti.it",
            "phone": "0123456789-1",
            "image": "img/partners/affiliato-artemserramenti.webp",
            "image_alt": "image alt",
            "logo": "img/partners/artemserramenti-logo.webp",
            "logo_alt": "image alt",
            "position" : { "lat": 45.45692 , "lng": 10.98020 }
        },
        {
            "id": 2,
            "name": "ATTECH",
            "address": "Via Francesco Baracca  14/B",
            "cap": "37138",
            "town": "Verona",
            "country": "Italia",
            "web": "www.atnt.it",
            "email": "info@atnt.it",
            "phone": "0123456789-2",
            "image": "img/partners/artemserramenti-image.webp",
            "image_alt": "image alt",
            "logo": "img/partners/artemserramenti-logo.webp",
            "logo_alt": "image alt",
            "position" : { "lat": 45.4421178, "lng": 10.9651043 }
        },
        {
            "id": 3,
            "name": "Artem Serramenti 3 ",
            "address": "Via Locchi 4",
            "cap": "37133",
            "town": "Verona",
            "country": "Italia",
            "web": "www.tre.it",
            "email": "info@artemserramenti.it",
            "phone": "0123456789",
            "image": "img/partners/artemserramenti-image.webp",
            "image_alt": "image alt",
            "logo": "img/partners/artemserramenti-logo.webp",
            "logo_alt": "image alt",
            "position" : { "lat": 44.956899085392806, "lng": 11.580195902034543 }
        },
        {
            "id": 4,
            "name": "Artem Serramenti 4",
            "address": "Via Locchi 4",
            "cap": "37134",
            "town": "Verona",
            "country": "Italia",
            "web": "www.quattro.it",
            "email": "info@artemserramenti.it",
            "phone": "0123456789",
            "image": "img/partners/artemserramenti-image.webp",
            "image_alt": "image alt",
            "logo": "img/partners/artemserramenti-logo.webp",
            "logo_alt": "image alt",
            "position" : { "lat": 46.556899085392806, "lng": 12.980195902034543 }
        },
        {
            "id": 5,
            "name": "Artem Serramenti 5",
            "address": "Via Locchi 4",
            "cap": "37135",
            "town": "Verona",
            "country": "Italia",
            "web": "www.cinque.it",
            "email": "info@artemserramenti.it",
            "phone": "0123456789",
            "image": "img/partners/artemserramenti-image.webp",
            "image_alt": "image alt",
            "logo": "img/partners/artemserramenti-logo.webp",
            "logo_alt": "image alt",
            "position" : { "lat": 43.556899085392806, "lng": 9.980195902034543 }
        },
        {
            "id": 6,
            "name": "Artem Serramenti 6",
            "address": "Via Locchi 4",
            "cap": "37136",
            "town": "Verona",
            "country": "Italia",
            "web": "www.sei.it",
            "email": "info@artemserramenti.it",
            "phone": "0123456789",
            "image": "img/partners/artemserramenti-image.webp",
            "image_alt": "image alt",
            "logo": "img/partners/artemserramenti-logo.webp",
            "logo_alt": "image alt",
            "position" : { "lat": 43.256899085392806, "lng": 10.580195902034543 }
        }
    ]
}

let data = {
    title: "Partner",
    boxes: {
        uno: {
            bottom_separator: false,
            top_separator: true,
            variation: "dark",
            orientation: "ltr",
            // dimension: "wide",
            animated: true,
            title: "Cerchi un Affiliato?",
            subtitle: "Inserisci città o cap e visualizza informazioni sulla mappa"
        },
    },
    db: db,

    partner: function partner(id) {
        const res = _.find(db.partners, {id:id});
        return res;
    }
}

module.exports = data;
