import * as comparison from "../utils/comparison.js";
import * as snapping from "../utils/snapping.js";

export function initPage() {
    console.log('INIT PAGE: standard');

    comparison.init();
    snapping.init();
}

/*function  initComparison() {
    gsap.utils.toArray(".comparisonSection").forEach(section => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: section,
                start: "center center",
                // makes the height of the scrolling (while pinning) match the width, thus the speed remains constant (vertical/horizontal)
                end: () => "+=" + section.offsetWidth,
                scrub: true,
                pin: true,
                anticipatePin: 1
            },
            defaults: {ease: "none"}
        });
        // animate the container one way...
        tl.fromTo(section.querySelector(".afterImage"), { xPercent: 100, x: 0}, {xPercent: 0})
            // ...and the image the opposite way (at the same time)
            .fromTo(section.querySelector(".afterImage img"), {xPercent: -100, x: 0}, {xPercent: 0}, 0);
    });
}*/

/*function initSnapping() {

    let duration = 10,
        sections = gsap.utils.toArray(".panel"),
        sectionIncrement = duration / (sections.length - 1),
        tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".container",
                pin: true,
                scrub: 1,
                snap: 1 / (sections.length - 1),
                start: "top top",
                end: "+=5000"
            }
        });

    tl.to(sections, {
        xPercent: -100 * (sections.length - 1),
        duration: duration,
        ease: "none"
    });

    // everything below this is just for the fading/scaling up which is NOT scrubbed - it's all dynamic,
    // triggered when each section enters/leaves so that the fading/scaling occurs at a consistent rate
    // no matter how fast you scroll!
    sections.forEach((section, index) => {
        let tween = gsap.from(section, {
            opacity: 0,
            scale: 0.6,
            duration: 1,
            force3D: true,
            paused: true
        });
        addSectionCallbacks(tl, {
            start: sectionIncrement * (index - 0.99),
            end: sectionIncrement * (index + 0.99),
            onEnter: () => tween.play(),
            onLeave: () => tween.reverse(),
            onEnterBack: () => tween.play(),
            onLeaveBack: () => tween.reverse()
        });
        index || tween.progress(1); // the first tween should be at its end (already faded/scaled in)
    });

    // helper function that lets us define a section in a timeline that spans between two times (start/end)
    // and lets us add onEnter/onLeave/onEnterBack/onLeaveBack callbacks
    function addSectionCallbacks(timeline, {start, end, param, onEnter, onLeave, onEnterBack, onLeaveBack}) {
        let trackDirection = animation => { // just adds a "direction" property to the animation that tracks the moment-by-moment playback direction (1 = forward, -1 = backward)
                let onUpdate = animation.eventCallback("onUpdate"), // in case it already has an onUpdate
                    prevTime = animation.time();
                animation.direction = animation.reversed() ? -1 : 1;
                animation.eventCallback("onUpdate", () => {
                    let time = animation.time();
                    if (prevTime !== time) {
                        animation.direction = time < prevTime ? -1 : 1;
                        prevTime = time;
                    }
                    onUpdate && onUpdate.call(animation);
                });
            },
            empty = v => v; // in case one of the callbacks isn't defined
        timeline.direction || trackDirection(timeline); // make sure direction tracking is enabled on the timeline
        start >= 0 && timeline.add(() => ((timeline.direction < 0 ? onLeaveBack : onEnter) || empty)(param), start);
        end <= timeline.duration() && timeline.add(() => ((timeline.direction < 0 ? onEnterBack : onLeave) || empty)(param), end);
    }

}*/

/*
function initGallery() {

   let panels = gsap.utils.toArray(".Gallery__ListItem");
    let tops = panels.map(panel => ScrollTrigger.create(
        {
            trigger: ".Gallery__ListItem",
            start: "top top"
        })
    );

    panels.forEach((panel, i) => {
        ScrollTrigger.create({
            trigger: panel,
            markers: false,
            start: () => panel.offsetHeight < window.innerHeight ? "top top" : "bottom bottom", // if it's shorter than the viewport, we prefer to pin it at the top
            pin: true,
            pinSpacing: false
        });
    });

    ScrollTrigger.create({
        snap: {
            snapTo: (progress, self) => {
                let panelStarts = tops.map(st => st.start), // an Array of all the starting scroll positions. We do this on each scroll to make sure it's totally responsive. Starting positions may change when the user resizes the viewport
                    snapScroll = gsap.utils.snap(panelStarts, self.scroll()); // find the closest one
                return gsap.utils.normalize(0, ScrollTrigger.maxScroll(window), snapScroll); // snapping requires a progress value, so convert the scroll position into a normalized progress value between 0 and 1
            },
            duration: 0.5
        }
    });

} */
