import {gsap} from "gsap";
import $ from "jquery";
import * as products from "../../views/data/pages/products.cjs"
import {breakpoints} from "../utils/breakpoints.js";

export function initPage() {
    console.log('INIT PAGE: categories');

    // DETAILS
    $('.Categories__ListItem').on('click', function (event) {
        event.stopPropagation();
        toggleCategoryDetail(true, $(this).data("category_id"), $(this).data("img_path"), $(this).data("href"));
    });

    $('#categories__id').on('click', function (event) {
        event.stopPropagation();
        toggleCategoryDetail(false);
    });

    $('.SlidingPanel__Close').on('click', function (event) {
        event.stopPropagation();
        toggleCategoryDetail(false);
    });

    const mm = gsap.matchMedia();
    mm.add({
        isDesktop: `(min-width: ${breakpoints.md}px)`,
        isMobile: `(max-width: ${breakpoints.md - 1}px)`
    }, (context) => {
        _page.init(context);
    });

}

let showDetail = false;

function toggleCategoryDetail(val = showDetail, id = -1, img_path = '', href = '#') {

    let c = {};
    if (id !== -1) {
        c = products.getCategory(id);
    }

    // IDS category using custom page
    if (href !== '#') {
        toggleCategoryDetail(false);
        window.location.href = href;
    } else {
        let showDetail = val;
        let timeout = 0;
        const $sl = $('#slidingpanel_category');
        const $body = $('body');
        if (showDetail && $sl.hasClass('isOpen')) {
            $sl.removeClass('isOpen');
            $body.removeClass('disableScroll');
            timeout = 700;
        }
        setTimeout(() => {
            if (showDetail) {
                $sl.addClass('isOpen');
                $body.addClass('disableScroll');
            } else {
                if ($sl.hasClass('isOpen')) {
                    $sl.removeClass('isOpen');
                    $body.removeClass('disableScroll');
                }
            }
            prepareCategoryDetailData(c, img_path);
        }, timeout);
    }
}

function prepareCategoryDetailData(c, img_path) {

    if (c === {}) return;

    const bid = c.brands[0].id;
    const b = products.getBrand(bid);
    // title
    $('#category_detail_title').text(c.name);
    // brand
    //$('#category_detail_brand').text(b.name);
    // brand logo
    let $img = $('#category_detail_brand_logo');
    $img.attr('alt', b.logo_alt);
    $img.attr('src', img_path + '/' + b.logo);
    // company
    //$('#partner_detail_company').text(p.company);
    // description
    $('#category_detail_description').text(c.note);
    // image
    $img = $('#category_detail_img')
    $img.attr('alt', c.image_alt);
    $img.attr('src', img_path + '/' + c.image);

    // materials
    $('#category_detail_materials').text(c.materials);
    // warranty
    //$('#category_detail_warranty').text(p.warranty);
    // contacts
    //$('#category_detail_address').text(p.address + ' ' + p.cap + ' ' + p.town);
    //$('#category_detail_email').text(p.email);
    //$('#category_detail_web').text(p.web);
    //$('#category_detail_phone').text(p.phone);
}

const _page = {
    isDesktop: false,
    isMobile: false,

    initScrollers: function () {
        if (this.isMobile) {
            const items = gsap.utils.toArray('.Categories__ListItem');
            items.forEach((item) => {
                gsap.from(item, {
                    scrollTrigger: {
                        markers: false,
                        start: 'top 40%',
                        end: 'bottom 40%',
                        trigger: item,
                        toggleClass: 'active'
                    }
                });
            });
        }
    },

    init: function (context) {
        let {isDesktop, isMobile} = context.conditions;
        this.isDesktop = isDesktop;
        this.isMobile = isMobile;

        this.initScrollers();
    }
}
