import {includes} from "lodash";

import $ from "jquery"

// Initialize and add the map
let map;
let _contacts = {};
let _lastinfowindow;

export async function initMap() {
    $("#ZoomIn").on('click', zoomIn);
    $("#ZoomOut").on('click', zoomOut);

    //$(".DealerLocator__SearchResultAddress").on('click', addressClick);

    drawMap();
}

async function drawMap() {

    // Request needed libraries.
    //@ts-ignore
    const {Map} = await google.maps.importLibrary("maps");


    // The map, centered at Progetto Casa
    map = new Map(document.getElementById("map"), {
        zoom: 15,
        mapId: "87225d67410ed3bd",
        disableDefaultUI: true,
        scrollwheel: false,
    });

    google.maps.event.addListener(map, 'click', function () {
        if (_lastinfowindow) {
            _lastinfowindow.close();
        }
    });

}

export async function createMarkers(contacts) {

    const {Marker} = await google.maps.importLibrary("marker");


    if (contacts.length === 0) return;
    _contacts = contacts;
    hideMarkers();

    let bounds = new google.maps.LatLngBounds();
    _contacts.forEach(contact => {
        if (contact.visible ?? true) {
            if (contact.marker) {
                contact.marker.setMap(map);
            } else {
                contact.marker = new Marker({
                    map: map,
                    position: contact.position,
                    title: contact.name,
                    //icon: '/assets/img/partners/artemserramenti-logo.png',
                });
                addInfoWindow(contact, prepareHtml(contact));

            }
            bounds.extend(contact.position);
        } else {
            if (contact.marker) {
                contact.marker.setMap(null);
            }
        }
    });

    map.setCenter(bounds.getCenter());
    //map.fitBounds(bounds);
    //map.setZoom(map.getZoom()-1);

    /*if (map.getZoom() > 15) {
        map.setZoom(15);
    }*/

}

function prepareHtml(contact) {

    const url_map = "https://maps.google.com/?q=" + contact.name + "@" + contact.position.lat + "," + contact.position.lng + ",12.21z";
    const url_web = "https://" + contact.web;
    const url_email = "mailto:" + contact.email;
    const url_phone = "tel:" + contact.phone;

    return "<div class='DealerLocator__DialogResults'>" +
        "  <div class='DealerLocator__SearchResult bodySansSmall' style='margin-top: 1vw' data-partner_id='" + contact.id + "' >" +
        "      <p class=\"DealerLocator__SearchResultName bodySerif\">" + contact.name + "</p>" +
        "      <div class=\"container-fluid\">" +
        "         <div class=\"row gx-4\">" +
        "             <div class=\"col-auto text-end\">" +
        "                <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-geo-alt-fill\" viewBox=\"0 0 16 16\">" +
        "                  <path d=\"M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z\"/>" +
        "                </svg>" +
        "             </div>" +
        "             <div class=\"col\">" +
        "                <a target='_blank' href=\"" + url_map + "\">" + contact.address + "<br>" + contact.cap + " " + contact.town + " " + contact.country +
        "                </a>" +
        "             </div>" +
        "         </div>" +
        "         <div class=\"row gx-4\">" +
        "           <div class=\"col-auto text-end\">" +
        "              <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-telephone-fill\" viewBox=\"0 0 16 16\">" +
        "                 <path fill-rule=\"evenodd\" d=\"M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z\"></path>" +
        "              </svg>" +
        "           </div>" +
        "           <div class=\"col\">" +
        "              <a  target='_blank' href=\"" + url_phone + "\">" + contact.phone + "</a>" +
        "           </div>" +
        "         </div>" +
        "         <div class=\"row gx-4\">" +
        "            <div class=\"col-auto text-end\">" +
        "               <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-globe\" viewBox=\"0 0 16 16\">" +
        "                  <path d=\"M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z\"/>" +
        "               </svg>" +
        "            </div>" +
        "            <div class=\"col\">" +
        "                <a  target='_blank' href=\"" + url_web + "\">" + url_web + "</a>" +
        "            </div>" +
        "         </div>" +
        "         <div class=\"row gx-4\">" +
        "            <div class=\"col-auto text-end\">" +
        "               <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-envelope-fill\" viewBox=\"0 0 16 16\">" +
        "                  <path d=\"M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z\"/>" +
        "               </svg>" +
        "            </div>" +
        "            <div class=\"col\">" +
        "               <a  target='_blank' href='" + url_email + "'>" + contact.email + "</a>" +
        "            </div>" +
        "         </div>" +
        "      </div>" +
        "   </div>" +
        " </div>";

}

function addInfoWindow(contact, html) {

    const infoWindow = new google.maps.InfoWindow({
        content: html
    });

    contact.marker.addListener("mouseover", () => {
        if (_lastinfowindow) {
            _lastinfowindow.close();
        }
        _lastinfowindow = infoWindow;
        infoWindow.open({
            anchor: contact.marker,
            map,
        });
    });

}

// Sets the map on all markers in the array.
function setMapOnAll(map) {
    for (let i = 0; i < _contacts.length; i++) {
        if (_contacts[i].marker) {
            _contacts[i].marker.setMap(map);
        }
    }
}

// Removes the markers from the map, but keeps them in the array.
function hideMarkers() {
    setMapOnAll(null);
}


function zoomIn() {
    console.log(map.getZoom());
    map.setZoom(map.getZoom() + 1);
}

// Shows any markers currently in the array.
function showMarkers() {
    setMapOnAll(map);
}

function zoomOut() {
    console.log(map.getZoom());
    map.setZoom(map.getZoom() - 1);
}

function addressClick(event) {

    let $target = $(event.target);
    let $parent = $target.parent();
    let pid = $parent.data('partner_id');
    if (pid) {
        const contact = _contacts.find(contact => contact.id === pid);
        //console.log(contact);
        const coordinates = contact.position;
        // ?q=MY%20LOCATION@lat,long
        const url = "https://maps.google.com/?q=" + contact.name + "@" + coordinates.lat + "," + coordinates.lng + ",12.21z";
        window.open(url, '_blank');
    }


}
