import $ from 'jquery';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger.js";

export function init() {
    //console.log('INIT ANIMATIONS');

    const animations = gsap.utils.toArray('.animate__animated[data-animation]');
    animations.forEach((animated) => {
        const $animated = $(animated),
            animation = $animated.data('animation');

        gsap.to(animated, {
            scrollTrigger: {
                trigger: animated,
                markers: false,
                onEnter: () => {
                    $animated.addClass('animate__' + animation);
                },
                onLeave: () => {
                    $animated.removeClass('animate__' + animation);
                }
            }
        });
    });

    const arrLetters = gsap.utils.toArray('.enterByLetters');
    arrLetters.forEach((letters) => {
        const $letters = $(letters),
            text = $letters.text().trim(),
            words = text.split(' ');

        let html = '';
        for (let w = 0, wlen = words.length; w < wlen; w++) {
            const chars = words[w].trim().split('');
            html += '<div class="word" style="position:relative; display: inline-block">';
            for (let i = 0, ilen = chars.length; i < ilen; i++) {
                html += '<div class="char" style="position:relative; display: inline-block">' + chars[i] + '</div>';
            }
            if (w < (wlen - 1)) {
                html += '<div class="char" style="position:relative; display: inline-block; padding: 0 .05em">&nbsp;</div>';
            }
            html += '</div>';
        }
        $letters.html(html);
        let chars = $letters.find('.char').get();

        gsap.to(letters, {
            scrollTrigger: {
                trigger: letters,
                markers: false,
                onLeave: () => {
                    //console.log("onLeave");
                    //$letters.removeClass('onScreen');
                },
                onEnter: () => {
                    //console.log("onEnter");
                    //$letters.html(html);
                    //chars = $letters.find('.char').get();
                    //gsap.set(letters, {perspective: 400});

                    gsap.fromTo(chars,
                        {
                            opacity: 0,
                            scale: 0,
                            y: 80,
                            rotationX: 180,
                            transformOrigin: "0% 50% -50",
                            autoAlpha: 0,
                        },
                        {
                            duration: 0.5,
                            rotationX: 0,
                            //transformOrigin: "0 0 0",
                            scale: 1,
                            //x: 0,
                            y: 0,
                            opacity: 1,
                            stagger: {
                                amount: 0.5,
                                from: 'random',
                            },
                            ease: "power2",
                            autoAlpha: 1
                        },
                    );

                    $letters.addClass('onScreen');
                },
                onEnterBack: () => {
                    //console.log("onEnterBack");
                    $letters.addClass('onScreen');
                },
                onLeaveBack: () => {
                    //console.log("onLeaveBack");
                    $letters.removeClass('onScreen');
                }
            }
        });
    });

    const arrParallaxs = gsap.utils.toArray('.ParallaxImage');
    arrParallaxs.forEach((parallax) => {
        const $parallax = $(parallax);
        //const isImageDark = $parallax.data("image-color") === "dark";

        gsap.to(parallax, {
            scrollTrigger: {
                onEnter: self => {
                    $parallax.addClass('onScreen');
                    //manageMenuColor(isImageDark);
                },
                onEnterBack: () => {
                    $parallax.addClass('onScreen');
                    //manageMenuColor(isImageDark);
                },
                trigger: parallax,
                start: "top 40%",
                toggleActions: "play none none none",
            }
        });
    });

    const menu = gsap.utils.toArray('[data-menu-color]');

    menu.forEach((section) => {
        const $section = $(section);
        ScrollTrigger.create({
            trigger: section,
            start: "top 4.5%",
            end: "bottom 4.5%",
            markers: false,
            onEnter: function ({progress, direction, isActive}) {
                changeMenuColor($section.data('menuColor'));
            },
            onEnterBack: function ({progress, direction, isActive}) {
                changeMenuColor($section.data('menuColor'));
            },
            onLeave: function ({progress, direction, isActive}) {
                changeMenuColor('dark');
            },
        });
    });

}

function changeMenuColor(color) {
    //console.log(color);
    let $nav = $('.Nav');
    if (color.toLowerCase() === 'light') {
        $nav.removeClass("DarkMenu");
        $nav.addClass("LightMenu");
    } else {
        $nav.removeClass("LightMenu");
        $nav.addClass("DarkMenu");
    }
}

export function manageMenuColor(isImageDark) {
    // menu color
    if (isImageDark) {
        changeMenuColor("light");
    } else {
        changeMenuColor("dark");
    }
}
