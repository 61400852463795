
let db = {
    "title": "Per qualsiasi informazione contattaci",
    "address_label": "Indirizzo",
    "phone_label": "Telefono",
    "email_label": "Email",
    "contacts": [
        {
            "id": 1,
            "name": "Progetto Casa",
            "address": "Via Locchi 4",
            "cap": "37124",
            "town": "Verona",
            "country": "Italia",
            "web": "www.progettocasa.srl",
            "email": "info@progettocasa.srl",
            "phone": "045 49 36 700",
            "phoneto": "+390454976700",
            "position" : { "lat": 45.456899085392806, "lng": 10.980195902034543 },
            "maps": "https://www.google.com/maps/place/Via+Vittorio+Locchi,+4,+37124+Verona+VR/@45.4569747,10.9753949,1151m",
        }
    ],
    "contactus": {
        "button": {
            "label": "Invia"
        },
        "name_label": "Nome",
        "email_label": "Email",
        "message_label": "Messaggio",
        "title": "Scrivici",
        "info": "Se sei interessato a diventare un affiliato, non mandare un messaggio con questo form, invece scrivici separatamente a info@progettocasa.srl\no via whatsapp al numero 045 49 36 700 e il nostro dipartimento ti ricontatterà dopo aver valutato le informazioni fornite."
    }
}

let data = {
    title: "Contatti",
    db: db
};

module.exports = data;
