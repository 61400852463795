import $ from "jquery";
import * as location from "../utils/location.js";
import * as contacts from "../../views/data/pages/contacts.cjs"

export function initPage() {
    console.log('INIT PAGE: contacts');

    $("#contactsmessageus_submit").on('click', validate);

    window.onSubmit = onSubmit;
    window.onLoad = onLoad;

    initInputs();

    //MAP
    location.initMap();

    location.createMarkers(contacts.db.contacts);
}

function onLoad() {

}

function validate(event) {
    event.preventDefault();

    const $name = $('#name');
    const $email = $('#email');
    const $message = $('#message');

    console.log($email.val());

    if (!validateEmail($email.val())) {
        $(".ContactsMessageUs__FormMessage--Error").html("Si è verificato un errore.\n Controlla di aver inserito la mail correttamente.");
    } else {
        grecaptcha.enterprise.ready(function () {
            grecaptcha.enterprise.execute()
                .then(function (token) {
                    console.log(token);
                    $.post('https://www.progettocasa.srl/_TEMP2/backend/process_TEST.php',
                        //$( "#contactsmessageus-form" ).serialize()
                        {
                            name: $name.val(),
                            email: $email.val(),
                            message: $message.val(),
                            token: token
                        }
                    ).done(function () {
                        $(".ContactsMessageUs__FormMessage--Error").html("");
                        $(".ContactsMessageUs__FormMessage--Success").html("L'invio della mail è avvenuto con successo.");
                    })
                        .fail(function () {
                            $(".ContactsMessageUs__FormMessage--Success").html("");
                            $(".ContactsMessageUs__FormMessage--Error").html("Si è verificato un errore durante l'invio della mail.\n Ci scusiamo e vi invitiamo a utilizzare il vostro client di posta.");
                        })
                        .always(function () {
                            //alert( "finished" );
                        });

                });

        });
    }
}

function validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return (true);
    }
    //alert("You have entered an invalid email address!")
    return (false);
}


function onSubmit(token) {
    console.log("onSubmit!!!");


}


function initInputs() {

    const $input = $('input');

    $input.focus(function () {
        $(this).parents('.form-group').addClass('focused');
    });

    $input.blur(function () {
        let inputValue = $(this).val();
        if (inputValue === "") {
            $(this).removeClass('filled');
            $(this).parents('.form-group').removeClass('focused');
        } else {
            $(this).addClass('filled');
        }
    })

    const $textarea = $('textarea');

    $textarea.focus(function () {
        $(this).parents('.form-group').addClass('focused');
    });

    $textarea.blur(function () {
        let inputValue = $(this).val();
        if (inputValue === "") {
            $(this).removeClass('filled');
            $(this).parents('.form-group').removeClass('focused');
        } else {
            $(this).addClass('filled');
        }
    })

}
