export * as about from './pages/about.js';
export * as brands from './pages/brands.js';
export * as categories from './pages/categories.js';
export * as contacts from './pages/contacts.js';
export * as governance from './pages/governance.js';
export * as products from './pages/products.js';
export * as home from './pages/home.js';
export * as partners_locator from './pages/partners_locator.js';
export * as partners from './pages/partners.js';
export * as mission from './pages/mission.js';
export * as strategy from './pages/strategy.js';
export * as standard from './pages/standard.js';
export * as terms_of_use from './pages/terms_of_use.js';


