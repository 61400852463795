import $ from "jquery";

$(document).ready(function () {
// obtain plugin
    let cc = initCookieConsent();

// run plugin with your configuration
    cc.run({
        current_lang: 'it',
        autoclear_cookies: true,                   // default: false
        page_scripts: true,                        // default: false

        // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
        // delay: 0,                               // default: 0
        // auto_language: null                     // default: null; could also be 'browser' or 'document'
        // autorun: true,                          // default: true
        // force_consent: false,                   // default: false
        // hide_from_bots: true,                   // default: true
        // remove_cookie_tables: false             // default: false
        // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
        // cookie_expiration: 182,                 // default: 182 (days)
        // cookie_necessary_only_expiration: 182   // default: disabled
        // cookie_domain: location.hostname,       // default: current domain
        // cookie_path: '/',                       // default: root
        // cookie_same_site: 'Lax',                // default: 'Lax'
        // use_rfc_cookie: false,                  // default: false
        // revision: 0,                            // default: 0

        onFirstAction: function (user_preferences, cookie) {
            // callback triggered only once
            console.log('COOKIE CONSENT onFirstAction');
            console.log('User accept type:', user_preferences.accept_type);
            console.log('User accepted these categories', user_preferences.accepted_categories)
            console.log('User reject these categories:', user_preferences.rejected_categories);
        },

        onAccept: function (cookie) {
            // ...
            console.log('COOKIE CONSENT onAccept');
            console.log(cookie);
        },

        onChange: function (cookie, changed_preferences) {
            // ...
            console.log('COOKIE CONSENT onChange');
            console.log(cookie, changed_preferences);
        },

        gui_options: {
            consent_modal: {
                layout: 'box',               // box/cloud/bar
                position: 'bottom left',     // bottom/middle/top + left/right/center
                transition: 'slide',           // zoom/slide
                swap_buttons: false            // enable to invert buttons
            },
            settings_modal: {
                layout: 'box',                 // box/bar
                // position: 'left',           // left/right
                transition: 'slide'            // zoom/slide
            }
        },

        languages: {
            'it': {
                consent_modal: {
                    title: 'Informativa cookie',
                    description: 'Questo sito utilizza cookie, essenziali per funzionare correttamente.<br>Modifica le <button type="button" data-cc="c-settings" class="cc-link">Preferenze</button> oppure leggi i nostri <a href="/terms-of-use" class="cc-link">Terms of use</a> per ulteriori informazioni.',
                    primary_btn: {
                        text: 'Accetta',
                        role: 'accept_all'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Rifiuta',
                        role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Preferenze cookie',
                    save_settings_btn: 'Salva preferenze',
                    accept_all_btn: 'Accetta tutto',
                    reject_all_btn: 'Rifiuta tutto',
                    close_btn_label: 'Chiudi',
                    cookie_table_headers: [
                        {col1: 'Name'},
                        {col2: 'Domain'},
                        {col3: 'Expiration'},
                        {col4: 'Description'}
                    ],
                    blocks: [
                        {
                            title: 'Utilizzo dei cookie 📢',
                            description: 'Uso i cookie per garantire le funzionalità di base del sito Web e per migliorare la tua esperienza online. Puoi scegliere di attivare/disattivare ogni categoria quando vuoi. Per maggiori dettagli relativi ai cookie e ad altri dati sensibili, si prega di leggere la <a href="/terms-of-use#privacy" class="cc-link">privacy policy</a>',
                        },
                        {
                            title: 'Strictly necessary cookies',
                            description: 'Questi cookie sono essenziali per il corretto funzionamento del mio sito web. Senza questi cookie il sito non funzionerebbe correttamente',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                            }
                        },
                        // {
                        //     title: 'Performance and Analytics cookies',
                        //     description: 'These cookies allow the website to remember the choices you have made in the past',
                        //     toggle: {
                        //         value: 'analytics',     // your cookie category
                        //         enabled: false,
                        //         readonly: false
                        //     },
                        //     cookie_table: [             // list of all expected cookies
                        //         {
                        //             col1: '^_ga',       // match all cookies starting with "_ga"
                        //             col2: 'google.com',
                        //             col3: '2 years',
                        //             col4: 'description ...',
                        //             is_regex: true
                        //         },
                        //         {
                        //             col1: '_gid',
                        //             col2: 'google.com',
                        //             col3: '1 day',
                        //             col4: 'description ...',
                        //         }
                        //     ]
                        // },
                        // {
                        //     title: 'Advertisement and Targeting cookies',
                        //     description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                        //     toggle: {
                        //         value: 'targeting',
                        //         enabled: false,
                        //         readonly: false
                        //     }
                        // },
                        {
                            title: 'Maggiori informazioni',
                            description: 'Per qualsiasi domanda in relazione alla nostra politica sui cookie e alle tue scelte, per favore <a class="cc-link" href="/contacts">contattaci</a>.',
                        },
                    ]
                }
            }
        }
    });
});
