//import $ from 'jquery';
import {gsap} from "gsap";

export function init() {
    //console.log('INIT COMPARISON');
    gsap.utils.toArray(".Comparison").forEach(section => {
        let tl = gsap.timeline({
            scrollTrigger: {
                markers: false,
                trigger: section,
                start: "center center",
                // makes the height of the scrolling (while pinning) match the width,
                // thus the speed remains constant (vertical/horizontal)
                end: () => "+=" + section.offsetWidth,
                scrub: true,
                pin: true,
                anticipatePin: 1
            },
            defaults: {ease: "none"}
        });
        // animate the container one way...
        tl.fromTo(section.querySelector(".Comparison__AfterImage"), { xPercent: 100, x: 0}, {xPercent: 0})
            // ...and the image the opposite way (at the same time)
            .fromTo(section.querySelector(".Comparison__AfterImage .Comparison__ImageBg"), {xPercent: -100, x: 0}, {xPercent: 0}, 0);
    });

}
