import $ from 'jquery';

const router = {
    getPageName: function() {
        const page = $('body').data('page') || '';

        if (page && page.length) {
            return page.trim();
        }
        else {
            return '';
        }
    }
}

export default router;
