import {gsap} from "gsap";
// Comparison
import * as comparison from "../utils/comparison.js";
// Snapping
import * as snapping from "../utils/snapping.js";

export function initPage() {
    console.log('INIT PAGE: strategy');
    comparison.init();
    snapping.init();
}


