import $ from "jquery";
import {gsap} from "gsap";
import {db, getProduct, getCompany} from "../../views/data/pages/products.cjs"

export function initPage() {
    console.log('INIT PAGE: Products');
    initPagination();
}

let showProductDetail = false;
let showFilter = false;

function createTablePagination() {

    let $table = this;
    let current = 1;
    let pageItems = 8;
    let pageDisplay = [];

    let category_id = $table.data('category_id');
    let $cards = $table.find('.CardProduct');
    let $filteredCards = !category_id ? $cards : $cards.filter((index, card) => {
            let $card = $(card);
            const p = getProduct($card.data("product_id"));
            return p['category_id'] === category_id;
        }
    );
    let totalItems = $filteredCards.length;
    let totalPages = Math.ceil(totalItems / pageItems);

    console.log("total items: ", totalItems, " total pages: ", totalPages);

    // Clean Pagination
    let $paginationList = $('.Products__PaginationList');
    $paginationList.html('');

    //Prepare Pagination & $pageDisplay assigning the Start Card and Last Card for each page.
    let lis = '';
    for (let i = 0; i < totalPages; i++) {
        let $start = (i * pageItems);
        let $end = ((i + 1) * pageItems) - 1;
        if ($end > totalItems) $end = totalItems;
        pageDisplay[i] = {cardStart: $start, cardEnd: $end};
        let strIndex = (i + 1) + '';
        lis += '<li class="Products__PaginationItem caps" data-pagination-index="' + strIndex + '">' + strIndex + '</li>';
    }

    $paginationList.html(lis);

    cardsShowHide(0);

    rearrangePagination();

    //change page on number click
    $('.Products__PaginationItem').on("click", function (event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        displayPage($(this), 'number');
        rearrangePagination();
    });

    //change on prev
    $('.Products__PaginationArrow--Prev').on("click", function (event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        displayPage($(this), 'prev');
        rearrangePagination()
    });

    //change on next
    $('.Products__PaginationArrow--Next').on("click", function (event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        displayPage($(this), 'next');
        rearrangePagination()
    });


    // ------------------------------------------ //
    //                  helpers
    // ------------------------------------------ //
    function cardsShowHide(pageIndex) {
        let index = 0;
        $('.CardProduct').hide();
        $filteredCards.each(function () {
            const p = getProduct($(this).data("product_id"));
            if (pageDisplay.length > 0 &&
                (category_id === '' || p['category_id'] === category_id) &&
                $(this).index() >= pageDisplay[pageIndex].cardStart && index <= pageDisplay[pageIndex].cardEnd) {
                $(this).fadeIn(200);
                index++;
            } else {
                $(this).hide();
            }
        });
    }

    function displayPage(thisButton, type) {
        if (type === 'number') {
            current = Math.floor(thisButton.attr("data-pagination-index"));
        } else if (type === 'first') {
            current = 1;
        } else if (type === 'prev') {
            if (current > 1) {
                current = current - 1;
            }
        } else if (type === 'next') {
            if (current < totalPages) {
                current = current + 1;
            }
        } else if (type === 'last') {
            current = totalPages;
        }

        cardsShowHide(current - 1);
        /*$table.find('.CardProduct').each(function () {
            if (pageDisplay.length > 0 &&
                (category_id === '' || $(this).data("product")['category_id'] === category_id) &&
                $(this).index() >= pageDisplay[0].cardStart && $(this).index() <= pageDisplay[0].cardEnd) {
                $(this).fadeIn(200);
            } else {
                $(this).hide();
            }
        });*/

    }

    function rearrangePagination() {


        //let $val = ($current < $totalPages) ? $current - 1 : $current - 2;
        let $pagination = $(".Products__Pagination");
        /*$pagination.find('.Products__PaginationItem').each(function () {
            if ($val > 0 && $val <= $totalPages) {
                $(this).attr("data-pagination-index", $val);
                $(this).text($val);
                if ($val === $current) {
                    $(this).addClass('current');
                } else {
                    $(this).removeClass('current');
                }
                $(this).fadeIn();
            } else {
                $(this).fadeOut();
            }
            $val += 1;
        });*/
        let val = 1;
        $pagination.find('.Products__PaginationItem').each(function () {
            if (val === current) {
                $(this).addClass('current');
            } else {
                $(this).removeClass('current');
            }
            val += 1;
        });

        /*$pagination.find(".Products__PaginationArrow--Prev").each(function () {
            if ($current === 1) {
                $(this).removeClass('active');
            } else {
                $(this).addClass('active');
            }
        });

        $pagination.find(".Products__PaginationArrow--Next").each(function () {
            if ($current === $totalPages) {
                $(this).removeClass('active');
            } else {
                $(this).addClass('active');
            }
        });*/

        window.scrollTo(0, 0);
    }

    // Throw error
    function throwError(content) {
        throw new Error("Pagination " + content);
    }

}

function initPagination() {
    // Products Pagination
    $.fn.createTablePagination = createTablePagination;
    let $table = $('#ProductsList');
    if ($table.length > 0) {
        $table.createTablePagination();
    }

    $('#products__id').on('click', function (event) {
        event.stopPropagation();
        toggleFilter(true);
        toggleProductDetail(true);
    });

    $('#Products__StickyFilterButton').on('click', function (event) {
        event.stopPropagation();
        toggleProductDetail(true);
        toggleFilter();
    });

    $('.FilterProducts__Link input').on('change', function (event) {
        event.stopPropagation();
        toggleFilter(true);
        toggleProductDetail(true);
        if ($(this).is(':checked')) {
            $('.FilterProducts__InputFake').not(this).prop('checked', false);
            $('#ProductsList').data('category_id', $(this).parent().data("category_id"));
            createStickyItem($(this).parent().data("category_name"));
        } else {
            $('#ProductsList').data('category_id', '');
            $('.Products__StickyFiltersList').html('');
        }
        $table.createTablePagination();
    });

    $('.SlidingPanel__Close').on('click', function (event) {
        event.stopPropagation();
        toggleFilter(true);
        toggleProductDetail(true);
    });

    const fpLink = $('.FilterProducts__Link');
    fpLink.on('mouseenter', function (event) {
        $(this).removeClass('anotherLinkHovered');
        $('.FilterProducts__Link').not(this).addClass('anotherLinkHovered');
    });

    fpLink.on('mouseleave', function (event) {
        $('.FilterProducts__Link').removeClass('anotherLinkHovered');
    });

    $('.CardProduct').on('click', function (event) {
        event.stopPropagation();
        toggleFilter(true);
        toggleProductDetail(false, $(this).data("product_id"), $(this).data("img_path"));
    });

    function createStickyItem(name) {
        let lis = '<li class="Products__StickyFiltersItem caps">';
        lis += name;
        lis += '<svg class="Products__StickyFiltersItemCross" viewBox="0 0 13 13" fill="none">';
        lis += '<path stroke="#4C4B42" d="M6.008 11.989v-11M11.496 6.5h-11"></path></svg></li>';
        $('.Products__StickyFiltersList').html(lis);

        $('.Products__StickyFiltersItem').on('click', function (event) {
            event.stopPropagation();
            const $pl = $('#ProductsList');
            $pl.data('category_id', '');
            $pl.data('category_name', '');
            $('.Products__StickyFiltersList').html('');
            $table.createTablePagination();
        });
    }

    function toggleFilter(val = false) {
        showFilter = !val;
        const $sl = $('#SlidingPanel_Filter');
        const $body = $('body');
        if (showFilter) {
            $sl.addClass('isOpen');
            $body.addClass('disableScroll');
        } else {
            $sl.removeClass('isOpen');
            $body.removeClass('disableScroll');
        }
    }

    function toggleProductDetail(val, product_id = -1, img_path = '') {
        showProductDetail = !val;
        let timeout = 0;
        const $sl = $('#SlidingPanel_Product');
        const $body = $('body');
        // if already opened
        if (showProductDetail && $sl.hasClass('isOpen')) {
            $sl.removeClass('isOpen');
            $body.removeClass('disableScroll');
            timeout = 700;
        }
        setTimeout(() => {
            if (showProductDetail) {
                $sl.addClass('isOpen');
                $body.addClass('disableScroll');
            } else {
                if ($sl.hasClass('isOpen')) {
                    $sl.removeClass('isOpen');
                    $body.removeClass('disableScroll');
                }
            }

            prepareProductDetailData(product_id, img_path);
        }, timeout);

    }

    function prepareProductDetailData(product_id, img_path) {

        if (product_id === -1) {
            return;
        }

        const p = getProduct(product_id);

        // brand
        const b = db.brands[p.brand];
        $('#product_detail_brand').text(b.name);
        // brand logo
        let $img = $('#product_detail_brand_logo');
        $img.attr('alt', b.logo_alt);
        $img.attr('src', img_path + b.logo);


        // company
        $('#product_detail_company').text(p.company);
        // description
        $('#product_detail_description').text(p.content);
        // image
        $img = $('#product_detail_img')
        $img.attr('alt', p.alt);
        $img.attr('src', img_path + '/' + p.image);
        // title
        $('#product_detail_title').text(p.title);
        // materials
        $('#product_detail_materials').text(p.materials);
        // warranty
        $('#product_detail_warranty').text(p.warranty);

        let c = getCompany(p.company_id);
        // address
        $('#product_detail_address').text(c.address + ' ' + c.cap + ' ' + c.town);
        // web
        $('#product_detail_web').text(c.web);
        // phone
        $('#product_detail_phone').text(c.phone);
        // email
        $('#product_detail_email').text(c.email);

    }
}
