import $ from "jquery";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger.js";

import * as snapping from "../utils/snapping.js";
import {manageMenuColor} from "../utils/animations.js";
import {breakpoints} from "../utils/breakpoints.js";

export function initPage() {
    console.log('INIT PAGE: home');

    const mm = gsap.matchMedia();
    mm.add({
        isDesktop: `(min-width: ${breakpoints.md}px)`,
        isMobile: `(max-width: ${breakpoints.md - 1}px)`
    }, (context) => {
        _page.init(context);
    });
}

const _page = {
    isDesktop: false,
    isMobile: false,

    initScrollers: function () {
        const showMarkers = false;

        if (history.scrollRestoration) {
            history.scrollRestoration = 'manual';
        }

        let isImageDark = $('.SectionSplitted__FirstSprites').data("image-color") === "dark";
        gsap.to('.SectionSplitted__FirstSpritesContainer', {
            scrollTrigger: {
                invalidateOnRefresh: true,
                trigger: ".SectionSplitted",
                toggleActions: "play pause reverse pause",
                toggleClass: "onScreen",
                markers: false, //{startColor: "purple", endColor: "orange", fontSize: "18px", fontWeight: "bold", indent: 20},
                scrub: true,
                start: "top top",
                end: "bottom bottom",
                pin: ".SectionSplitted__StickyContainer",
                onEnterBack: self => {
                    manageMenuColor(isImageDark)
                },
                onUpdate: self => {
                    this.translateSectionSplittedFirstView(self.progress);
                    manageMenuColor(isImageDark && ((self.direction === 1 && self.progress > 0.4) || (self.direction === -1 && self.progress > 0.4)));
                }
            }
        });

        gsap.to('.SectionSplitted__StickyContainer', {
            scrollTrigger: {
                invalidateOnRefresh: true,
                start: "top bottom-=1",
                end: "+=" + (window.innerHeight * 1.70),
                once: false,
            }
        });

        // vertical image scroll
        /*//let sections = gsap.utils.toArray(".SectionSplitted__SecondSectionInSticky");
        //let container = document.querySelector('.SectionSplitted__ScrollFrameContainer');
        //let h = container.offsetHeight;
        gsap.to(".SectionSplitted__SecondSectionInSticky", {
            //yPercent: -100 * (sections.length - 1),
            //ease: "none",
            //duration: 6,
            scrollTrigger: {
                trigger: ".SectionSplitted__ScrollFrameContainer",
                pin: true,
                scrub: true,
                markers: false,
                start: "top bottom",
                onUpdate: self => {
                    const setTop = gsap.quickSetter(".SectionSplitted__SecondSectionInSticky", "translateY");
                    setTop("-" + self.progress*2);
                }
                //end: `+=${h}`
            }
        });*/
    },

    translateSectionSplittedFirstView: function (progress) {

        let sectionLeft = (50 - (progress * 50));
        if (this.isMobile) {
            sectionLeft = (100 - (progress * 100));
        }
        const setSectionLeft = gsap.quickSetter(".SectionSplitted__FirstSection", "left");
        setSectionLeft(sectionLeft + "%");

        if (this.isDesktop) {
            const sectionWidth = progress * 50 + 50;
            const borderX = (25 - (progress * 25));
            const borderY = (16 - (progress * 16));

            const setSectionWidth = gsap.quickSetter(".SectionSplitted__FirstSection", "width")
            const setBorderWidth = gsap.quickSetter(".SectionSplitted__FirstFrameBorderLeft, .SectionSplitted__FirstFrameBorderRight", "width");
            const setBorderHeight = gsap.quickSetter(".SectionSplitted__FirstFrameBorderTop, .SectionSplitted__FirstFrameBorderBottom", "height");

            setSectionWidth(sectionWidth + "%");
            setBorderWidth(borderX + "%");
            setBorderHeight(borderY + "%");
        }

        // Opacities
        let opacity = 1 - 8 * progress;
        if (opacity < 0) {
            opacity = 0;
        }
        const setOpacity = gsap.quickSetter(".SectionSplitted__HeaderTitle, .SectionSplitted__ScrollCta", "opacity");
        setOpacity(opacity);
    },

    snapByProximity: function () {
        const Scroll = new function () {
            let sections
            let page
            let main
            let scrollTrigger
            let tl
            let win

            // Init
            this.init = () => {
                sections = document.querySelectorAll('section')
                page = document.querySelector('#page')
                main = document.querySelector('.SectionSplitted__StickyContainer')
                win = {
                    w: window.innerWidth,
                    h: window.innerHeight
                }

                this.setupTimeline()
                this.setupScrollTrigger()
                window.addEventListener('resize', this.onResize)
            }

            // Setup ScrollTrigger
            this.setupScrollTrigger = () => {
                page.style.height = (this.getTotalScroll() + win.h) + 'px'

                scrollTrigger = ScrollTrigger.create({
                    id: 'mainScroll',
                    trigger: '.SectionSplitted__StickyContainer',
                    animation: tl,
                    pin: true,
                    scrub: true,
                    markers: false,
                    snap: {
                        snapTo: (value) => {

                            let labels = Object.values(tl.labels)

                            const snapPoints = labels.map(x => x / tl.totalDuration());
                            const proximity = 0.1

                            console.log(tl.labels, tl.totalDuration(), labels, snapPoints)

                            for (let i = 0; i < snapPoints.length; i++) {
                                if (value > snapPoints[i] - proximity && value < snapPoints[i] + proximity) {
                                    return snapPoints[i]
                                }
                            }
                        },
                        duration: {min: 0.2, max: 0.6},
                    },
                    start: 'top top',
                    end: '+=' + this.getTotalScroll(),
                })
            }

            // Setup timeline
            this.setupTimeline = () => {
                tl = gsap.timeline()
                tl.addLabel("label-initial")

                sections.forEach((section, index) => {
                    const nextSection = sections[index + 1]
                    if (!nextSection) return

                    tl.to(nextSection, {
                        y: -1 * nextSection.offsetHeight,
                        duration: nextSection.offsetHeight,
                        ease: 'linear',
                    })
                        .addLabel(`label${index}`)
                })
            }

            // On resize
            this.onResize = () => {
                win = {
                    w: window.innerWidth,
                    h: window.innerHeight
                }

                this.reset()
            }

            // Reset
            this.reset = () => {
                if (typeof ScrollTrigger.getById('mainScroll') === 'object') {
                    ScrollTrigger.getById('mainScroll').kill()
                }

                if (typeof tl === 'object') {
                    tl.kill()
                    tl.seek(0)
                }

                document.body.scrollTop = document.documentElement.scrollTop = 0
                this.init()
            }

            // Get total scroll
            this.getTotalScroll = () => {
                let totalScroll = 0
                sections.forEach(section => {
                    totalScroll += section.offsetHeight
                })
                totalScroll -= win.h
                return totalScroll
            }
        }
        Scroll.init();
    },


    init: function (context) {
        let {isDesktop, isMobile} = context.conditions;
        this.isDesktop = isDesktop;
        this.isMobile = isMobile;

        this.initScrollers();
        snapping.init();

        //SnapByBroximity
        //snapByProximity();
    }
}
